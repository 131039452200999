<script lang="ts">
</script>

<div class="has-text-centered p-12 notification is-rounded">
	<h1 class="title">
		<span class="icon is-large is-warning"
			><ion-icon name="hand-left"></ion-icon></span
		>
	</h1>
	<h1 class="title">Du är portad</h1>
</div>

<style>
	.notification {
		margin-top: 10%;
		width: 20%;
		max-width: 20%;
		margin-left: 40%;
	}
</style>
