<script lang="ts">
	export let stream: MediaStream;

	$: streamHasVideo = stream && stream.getVideoTracks().length;

	function srcObject(node: any, stream: any) {
		node.srcObject = stream;
		return {
			update(newStream: any) {
				if (node.srcObject != newStream) {
					node.srcObject = newStream;
				}
			},
		};
	}
</script>

{#if streamHasVideo}
	<video
		class="watch-video"
		use:srcObject={stream}
		controls={false}
		autoplay
		playsinline
		muted={true}
	></video>
{/if}
